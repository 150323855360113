import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Layout } from 'components';

const StyledTitle = styled.h1`
  margin: 60px 0 80px;
`;

const PrivacyPolicyPage = ({
  data: {
    prismicPrivacyPage: {
      data: { title, content },
    },
  },
}) => (
  <Layout>
    <div className="uk-container uk-container-medium uk-padding-large">
      <StyledTitle>{title.text}</StyledTitle>
      <div
        className="uk-width-2-3@m uk-width-1-1"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
    </div>
  </Layout>
);

export default PrivacyPolicyPage;

export const query = graphql`
  query {
    prismicPrivacyPage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
  }
`;
